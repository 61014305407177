import React, { Component } from 'react'

export default class Timeline extends Component {
  render() {
    return (
      <div>
        <section className="colorlib-experience" data-section="timeline" id="blogsection">
          <div className="colorlib-narrow-content">
            <div className="row">
              <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
                <span className="heading-meta">recent entry</span>
                <h2 className="colorlib-heading animate-box">PhotoBlog</h2>
              </div>
            </div>
			
            <div className="row">
				<div className="col-md-12">
					<div className="photoblog" id="yellowstone1000px">
						<img src={"./images/yellowstone1000px.jpg"} alt="Yellow Stone National Park" />
						  <p><small>
							Canon EOS RP | 76mm | f/8 | 1/320sec | ISO 100
						  </small></p>
						  <p>
							<a href="https://en.wikipedia.org/wiki/Yellowstone_National_Park"target="_blank" rel="noopener noreferrer" >Yellow Stone National Park - Wyoming.</a> (Roadtrip USA - Summer 2023)
						  </p>
					</div>
				</div>
				<div className="col-md-12">
					<div className="photoblog" id="devilstower1000px">
						<img src={"./images/devilstower1000px.jpg"} alt="Devils Tower Wyoming" />
						  <p><small>
							Canon EOS RP | 37mm | f/9 | 1/320sec | ISO 100
						  </small></p>
						  <p>
							<a href="https://en.wikipedia.org/wiki/Devils_Tower"target="_blank" rel="noopener noreferrer" >Devils Tower - Wyoming.</a> (Roadtrip USA - Summer 2023)
						  </p>
					</div>
				</div>
				<div className="col-md-12">
					<div className="photoblog" id="mountrushmore1000px">
						<img src={"./images/mountrushmore1000px.jpg"} alt="Mount Rushmore National Park - South Dakota" />
						  <p><small>
							Canon EOS RP | 65mm | f/8 | 1/320sec | ISO 125
						  </small></p>
						  <p>
							<a href="https://en.wikipedia.org/wiki/Mount_Rushmore"target="_blank" rel="noopener noreferrer" >Mount Rushmore National Park - South Dakota.</a> (Roadtrip USA - Summer 2023)
						  </p>
					</div>
				</div>
				<div className="col-md-12">
					<div className="photoblog" id="boat1000px">
						<img src={"./images/boat1000px.jpg"} alt="boat Bangka at Marlin's Beach Resort Sta.Fe, Bantayan Island" />
						  <p><small>
							Canon Rebel SL1 | 17mm | f/11 | 21sec | ISO 100
						  </small></p>
						  <p>
							Bangka - wooden outrigger boat <a href="http://www.marlin-bantayan.com/en/home.html"target="_blank" rel="noopener noreferrer" >Marlin's Beach Resort.</a> <a href="https://sugbo.ph/2018/beach-resorts-sta-fe-bantayan/" target="_blank" rel="noopener noreferrer" >Sta.Fe, Bantayan Island</a>
						  </p>
					</div>
				</div>
				 <div className="col-md-12">
					<div className="photoblog" id="beach1000px">
						<img src={"./images/beach1000px.jpg"} alt="Beach Log" />
						  <p><small>
							Canon Rebel SL1 | 17mm | f/10 | 13sec | ISO 100
						  </small></p>
						  <p>
							Log on beach.
						  </p>
					</div>
				  </div>
				 <div className="col-md-12">
					<div className="photoblog" id="danaoseaport1000px">
						<img src={"./images/danaoseaport1000px.jpg"} alt="Danao City Boardwalk" />
						  <p><small>
							Canon 350D | 50mm | f/11 | 1/800sec | ISO 100
						  </small></p>
						  <p>
							Sunrise at Danao City Boardwalk.
						  </p>
					</div>
				  </div>
				  <div className="col-md-12">
					<div className="photoblog" id="beachflag1000px">
						<img src={"./images/beachflag1000px.jpg"} alt="Beach flag at Marlin's Beach Resort Sta.Fe, Bantayan Island" />
						  <p><small>
							Canon Rebel SL1 | 33mm | f/8 | 1/160sec | ISO 100
						  </small></p>
						  <p>
							Beach flag. <a href="http://www.marlin-bantayan.com/en/home.html"target="_blank" rel="noopener noreferrer" >Marlin's Beach Resort.</a> <a href="https://sugbo.ph/2018/beach-resorts-sta-fe-bantayan/" target="_blank" rel="noopener noreferrer" >Sta.Fe, Bantayan Island</a>
						  </p>
					</div>
				  </div>
				   <div className="col-md-12">
					<div className="photoblog" id="port1000px">
						<img src={"./images/port1000px.jpg"} alt="Boat dock at Marlin's Beach Resort Sta.Fe, Bantayan Island" />
						  <p><small>
							Canon Rebel SL1 | 17mm | f/11 | 25sec | ISO 100
						  </small></p>
						  <p>
							Boat dock. <a href="http://www.marlin-bantayan.com/en/home.html"target="_blank" rel="noopener noreferrer" >Marlin's Beach Resort.</a> <a href="https://sugbo.ph/2018/beach-resorts-sta-fe-bantayan/" target="_blank" rel="noopener noreferrer" >Sta.Fe, Bantayan Island</a>
						  </p>
					</div>
				  </div>
				  <div className="col-md-12">
					<div className="photoblog" id="ecofarmslow1000px">
						<img src={"./images/ecofarmslow1000px.jpg"} alt="Durano Eco Farm Rushing River Danao City, Cebu" />
						  <p><small>
							Canon Rebel SL1 | 17mm | f/10 | 81sec | ISO 100
						  </small></p>
						  <p>
							Long exposure (1 min and 21 secs) shot of a rushing river at <a href="https://sugbo.ph/2018/durano-eco-farm-and-spring-resort/"target="_blank" rel="noopener noreferrer" >Durano Eco Farm.</a>
						  </p>
					</div>
				  </div>
				  <div className="col-md-12">
					<div className="photoblog" id="ecofarm1000px">
						<img src={"./images/ecofarm1000px.jpg"} alt="Durano Eco Farm Fresh spring water pools Danao City, Cebu" />
						  <p><small>
							Canon Rebel SL1 | 17mm | f/5 | 1/100sec | ISO 100
						  </small></p>
						  <p>
							 Fresh spring water pools at <a href="https://sugbo.ph/2018/durano-eco-farm-and-spring-resort/"target="_blank" rel="noopener noreferrer" >Durano Eco Farm.</a>
						  </p>
					</div>
				  </div>
				<div className="col-md-12">
					<div className="photoblog" id="cowgirl1000px">
						<img src={"./images/cowgirl1000px.jpg"} alt="Cowgirl Virden Rodeo" />
						  <p><small>
							Canon Rebel SL1 | 250mm | f/7.1 | 1/800sec | ISO 400
						  </small></p>
						  <p>
							Cowgirl doing the Barrel Racing run. 2017 <a href="https://www.virdenindoorrodeo.ca/" target="_blank" rel="noopener noreferrer" >Virden Rodeo</a>.
						  </p>
					</div>
				  </div>
				<div className="col-md-12">
					<div className="photoblog" id="shed1000px">
						<img src={"./images/shed1000px.jpg"} alt="Gazebo in winter Virden Manitoba" />
						  <p><small>
							Canon Rebel SL1 | 40mm | f/8 | 1/320sec | ISO 200
						  </small></p>
						  <p>
							Gazebo in winter.
						  </p>
					</div>
				  </div>
				  <div className="col-md-12">
					<div className="photoblog" id="winnipegalley1000px">
						<img src={"./images/winnipegalley1000px.jpg"} alt="Winnipeg street photo walk" />
						  <p><small>
							Canon 350D | 23mm | f/7.1 | 1/500sec | ISO 100
						  </small></p>
						  <p>
							<a href="https://www.tourismwinnipeg.com/" target="_blank" rel="noopener noreferrer" >Winnipeg</a> street photography walk series. Found this dark alley in one of the streets of Winnipeg and then I noticed the contrasting building on the backdrop.
						  </p>
					</div>
				  </div>
				  <div className="col-md-12">
					<div className="photoblog" id="couldron1000px">
						<img src={"./images/couldron1000px.jpg"} alt="Winnipeg street photo walk PanAm Games The Forks park" />
						  <p><small>
							Canon 350D | 23mm | f/7.1 | 1/500sec | ISO 100
						  </small></p>
						  <p>
							<a href="https://www.tourismwinnipeg.com/" target="_blank" rel="noopener noreferrer" >Winnipeg</a> street photography walk series. 1999 Pan American Game Cauldron inside The Forks park.
						  </p>
					</div>
				  </div>
				  <div className="col-md-12">
					<div className="photoblog" id="woodbine1000px">
						<img src={"./images/woodbine1000px.jpg"} alt="Woodbine Motel Winnipeg street photo walk" />
						  <p><small>
							Canon 350D | 29mm | f/7.1 | 1/200sec | ISO 100
						  </small></p>
						  <p>
							<a href="https://www.tourismwinnipeg.com/" target="_blank" rel="noopener noreferrer" >Winnipeg</a> street photography walk series. <a href="http://www.mhs.mb.ca/docs/sites/woodbinehotel.shtml" target="_blank" rel="noopener noreferrer" >Woodbine hotel</a> near the busy Portage and Main intersection.
						  </p>
					</div>
				  </div>
				  <div className="col-md-12">
					<div className="photoblog" id="winnipegwallart1000px">
						<img src={"./images/winnipegwallart1000px.jpg"} alt="Wall Art Winnipeg street photo walk" />
						  <p><small>
							Canon 350D | 29mm | f/7.1 | 1/200sec | ISO 100
						  </small></p>
						  <p>
							<a href="https://www.tourismwinnipeg.com/" target="_blank" rel="noopener noreferrer" >Winnipeg</a> street photography walk series. Wall arts like this one are common sights with the old buildings in downtown Winnipeg.
						  </p>
					</div>
				  </div>
				  <div className="col-md-12">
					<div className="photoblog" id="StBoniface1000px">
						<img src={"./images/StBoniface1000px.jpg"} alt="St. Boniface Cathedral Winnipeg" />
						  <p><small>
							Canon 350D | 21mm | f/7.1 | 1/640sec | ISO 100
						  </small></p>
						  <p>
							<a href="https://www.cathedralestboniface.ca/index.php?lang=en" target="_blank" rel="noopener noreferrer" >Saint Boniface</a> Cathedral - <a href="https://www.tourismwinnipeg.com/" target="_blank" rel="noopener noreferrer" >Winnipeg</a>, Manitoba.<br/>
							Historically, the original whole structure was destroyed by fire. Only the facade and walls remain.
						  </p>
					</div>
				  </div>
				  <div className="col-md-12">
					<div className="photoblog" id="EsplanadeRiel1000px">
						<img src={"./images/EsplanadeRiel1000px.jpg"} alt="Esplanade Riel Pedestrian Bridge Winnipeg" />
						  <p><small>
							Canon 350D | 42mm | f/7.1 | 1/800sec | ISO 100
						  </small></p>
						  <p>
							<a href="https://www.tourismwinnipeg.com/things-to-do/attractions/display,listing/05735/esplanade-riel-pedestrian-bridge" target="_blank" rel="noopener noreferrer" >Esplanade Riel Pedestrian Bridge</a> 
						  </p>
					</div>
				  </div>
				  <div className="col-md-12">
					<div className="photoblog" id="legislative1000px">
						<img src={"./images/legislative1000px.jpg"} alt="Manitoba Legislative Building Winnipeg" />
						  <p><small>
							Canon 350D | 26mm | f/8 | 1/400sec | ISO 100
						  </small></p>
						  <p>
							Native Canadian Indian rock sculpture with the <a href = "https://www.gov.mb.ca/legislature/visiting/index.html" target="_blank" rel="noopener noreferrer" >Manitoba Legislative</a> building in the background. Sculptures as this one were used as landmarks or for navigations.
						  </p>
					</div>
				  </div>
				  <div className="col-md-12">
					<div className="photoblog" id="lakelouise1000px">
						<img src={"./images/lakelouise1000px.jpg"} alt="Lake Louise Banff Alberta Canada" />
						  <p><small>
							Canon 350D | 17mm | f/7.1 | 1/200sec | ISO 100
						  </small></p>
						  <p>
							Canoes at the beautiful <a href = "https://www.banfflakelouise.com/" target="_blank" rel="noopener noreferrer" >Lake Louise - Banff</a>, Alberta.
						  </p>
					</div>
				  </div>
				   <div className="col-md-12">
					<div className="photoblog" id="neepawalily1000px">
						<img src={"./images/neepawalily1000px.jpg"} alt="Neepawa Lily Festival" />
						  <p><small>
							Canon 350D | 45mm | f/2.8 | 1/640sec | ISO 800
						  </small></p>
						  <p>
							Winning photo entry in the <a href="http://www.neepawa.ca/neepawa-lily-festival/" target="_blank" rel="noopener noreferrer" >Neepawa Lily Festival</a> 2012 Photo Contest.
						  </p>
					</div>
				  </div>
				  <div className="col-md-12">
					<div className="photoblog" id="basilicacandle1000px">
						<img src={"./images/basilicacandle1000px.jpg"} alt="Candle lighting at Basilica Minore del Sto. Nino Cebu" />
						  <p><small>
							Canon Rebel SL1 | 50mm | f/10 | 1/400sec | ISO 400
						  </small></p>
						  <p>
							Lighting a candle at the <a href="https://santoninodecebubasilica.org/" target="_blank" rel="noopener noreferrer" >Basilica Minore del Sto. Nino</a> - Cebu. 
						  </p>
					</div>
				  </div>
				  <div className="col-md-12">
					<div className="photoblog" id="guitars1000px">
						<img src={"./images/guitars1000px.jpg"} alt="Guitars at Basilica Minore del Sto. Nino Cebu" />
						  <p><small>
							Canon 350D | 50mm | f/2.8 | 1/250sec | ISO 400
						  </small></p>
						  <p>
							Locally made guitars on display near the <a href="https://santoninodecebubasilica.org/" target="_blank" rel="noopener noreferrer" >Basilica Minore del Sto. Nino</a> - Cebu. Shot with a Canon nifty-fity lens.
						  </p>
					</div>
				  </div>
				  <div className="col-md-12">
					<div className="photoblog" id="basilica1000px">
						<img src={"./images/basilica1000px.jpg"} alt="Basilica Minore del Sto. Nino Cebu Pilgrim area" />
						  <p><small>
							Canon Rebel SL1 | 17mm | f/10 | 1/250sec | ISO 400
						  </small></p>
						  <p>
							<a href="https://santoninodecebubasilica.org/" target="_blank" rel="noopener noreferrer" >Basilica Minore del Sto. Nino</a> - Cebu Pilgrim area.
						  </p>
					</div>
				  </div>
				   <div className="col-md-12">
					<div className="photoblog" id="basilicacross1000px">
						<img src={"./images/basilicacross1000px.jpg"} alt=">Magellan's Cross Basilica Minore del Sto. Nino Cebu" />
						  <p><small>
							Canon Rebel SL1 | 17mm | f/4 | 1/60sec | ISO 400
						  </small></p>
						  <p>
							The famous <a href="https://www.cebucitytour.com/cebu-destinations/cebu-magellans-cross/" target="_blank" rel="noopener noreferrer" >Magellan's Cross</a> near the <a href="https://santoninodecebubasilica.org/" target="_blank" rel="noopener noreferrer" >Basilica Minore del Sto. Nino</a> - Cebu. 
						  </p>
					</div>
				  </div>
				  <div className="col-md-12">
					<div className="photoblog" id="lighthouse1000px">
						<img src={"./images/lighthouse1000px.jpg"} alt="Bagacay Point light house Liloan Cebu" />
						  <p><small>
							Canon 350D | 50mm | f/6.3 | 1/500sec | ISO 100
						  </small></p>
						  <p>
							Liloan <a href="https://en.wikipedia.org/wiki/Bagacay_Point_Lighthouse" target="_blank" rel="noopener noreferrer" >Bagacay Point</a> light house.
						  </p>
					</div>
				  </div>
				   <div className="col-md-12">
					<div className="photoblog" id="chopsue1000px">
						<img src={"./images/chopsue1000px.jpg"} alt="chopsue dish" />
						  <p><small>
							Canon 350D | 50mm | f/2.8 | 1/160sec | ISO 100
						  </small></p>
						  <p>
							Chopsue dish. Shot with a Canon nifty-fity lens.
						  </p>
					</div>
				  </div>
				  <div className="col-md-12">
					<div className="photoblog" id="beachview1000px">
						<img src={"./images/beachview1000px.jpg"} alt="Beach View Sta.Fe, Bantayan Island Cebu" />
						  <p><small>
							Canon 350D | 22mm | f/6.3 | 1/200sec | ISO 100
						  </small></p>
						  <p>
							Beach view in <a href="https://sugbo.ph/2018/beach-resorts-sta-fe-bantayan/" target="_blank" rel="noopener noreferrer" >Sta.Fe, Bantayan Island</a> - Cebu. This was a self-portrait (a.k.a selfie) with my wife and I. Camera was placed on a stable rock with timer set to 10sec. <br/>
							Just enough time for me going back to the chair and mesmerize with the view. Circular polarizer was used for better sky gradient and glare free sea line.
						  </p>
					</div>
				  </div>
				  <div className="col-md-12">
					<div className="photoblog" id="bantayan1000px">
						<img src={"./images/bantayan1000px.jpg"} alt="Ogtong Cave Resort Sta.Fe, Bantayan Island Cebu" />
						  <p><small>
							Canon 350D | 24mm | f/8 | 1/80sec | ISO 100
						  </small></p>
						  <p>
							Fantastic overlooking scenery near <a href="https://www.tripadvisor.ca/Hotel_Review-s1-g2450335-d646247-Reviews-Ogtong_Cave_Resort-Santa_Fe_Bantayan_Island_Cebu_Island_Visayas.html" target="_blank" rel="noopener noreferrer" >Ogtong Cave Resort</a>. Situated 100kms north of Cebu City in the town of <a href="https://sugbo.ph/2018/beach-resorts-sta-fe-bantayan/" target="_blank" rel="noopener noreferrer" >Sta. Fe, Bantayan Island</a>. <br/>
							I used a circular polarizer filter on this shot which resulted to an awesome blue color gradient of the sky as well as suppressing glare from the surface of the sea.
						  </p>
					</div>
				  </div>
				  <div className="col-md-12">
					<div className="photoblog" id="Fireworks1000px">
						<img src={"./images/Fireworks1000px.jpg"} alt="Fireworks Brandon Sun Summer Fair Photo Contest Best Night Life" />
						  <p><small>
							Canon 350D | 17mm | f/10 | 4sec | ISO 100
						  </small></p>
						  <p>
							This was my winning photo entry in the <a href="https://www.brandonsun.com/" target="_blank" rel="noopener noreferrer" >Brandon Sun</a> 2012 Summer Fair Photo Contest - "Best Night Life" category.
						  </p>
					</div>
				  </div>
				  <div className="col-md-12">
					<div className="photoblog" id="fatherNdaughter1000px">
						<img src={"./images/fatherNdaughter1000px.jpg"} alt="Father and daughter portrait black and white" />
						<p><small>
							Canon 350D | 50mm | f/2.8 | 1/200sec | ISO 100
						  </small></p>
						  <p>
							Father and daughter. Canon nifty-fity lens was used for this shot. Flash fired taming the harsh midday sun at the back.
						  </p>
					</div>
				  </div>
				</div>
			
          </div>
        </section>
      </div>
    )
  }
}
