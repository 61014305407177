import React, { Component } from 'react'

export default class Sidebar extends Component {
  render() {
    return (
      <div>
        <div>
          <nav href="#navbar" className="js-colorlib-nav-toggle colorlib-nav-toggle" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar"><i /></nav>
          <aside id="colorlib-aside" className="border js-fullheight">
            <div className="text-center">
              <div className="author-img" style={{backgroundImage: 'url(images/about.jpg)'}} />
              <h1 id="colorlib-logo"><a href="index.html">TOLZ</a></h1>
              <span className="email"><i className="icon-mail"></i> <a href={"mailto:" + this.props.email}>laotech77@gmail.com</a> </span>
            </div>
            <nav id="colorlib-main-menu" role="navigation" className="navbar">
              <div id="navbar" className="collapse">
                <ul>
                  <li className="active"><a href="#home" data-nav-section="home">Introduction</a></li>
                  <li><a href="#about" data-nav-section="about">About</a></li>
                  <li><a href="#timeline" data-nav-section="timeline">PhotoBlog</a></li>
                </ul>
              </div>
            </nav>
            <nav id="colorlib-main-menu">
              <ul>
                <li><a href="https://github.com/alao77" target="_blank" rel="noopener noreferrer"><i className="icon-github"></i></a></li>
                <li><a href="https://www.flickr.com/photos/tolz" target="_blank" rel="noopener noreferrer"><i className="icon-blogger2"></i></a></li>
              </ul>
            </nav>
            <div className="colorlib-footer">
              <p><small>
                  Made with <i className="icon-heart" aria-hidden="true" /> and <i className="icon-beer" aria-hidden="true"></i><br></br>
                  Thanks <a href="https://colorlib.com" target="_blank" rel="noopener noreferrer">Colorlib</a> for inspiration 
              </small></p>
              <p><small>
                Update as of Feb 2024:<br></br>
                 - updated Nodes.JS to v20.11.0 <br></br> (recent security fixes!!)<br></br>
                 - added new photos from our family <br></br> roadtrip USA - Summer 2023<br></br>
                 - minor mobile version fixes
              </small></p>
			  <p><small>
                Powered by: <a href="https://reactjs.org/" target="_blank" rel="noopener noreferrer">ReactJS</a>
              </small></p>
            </div>
          </aside>
        </div>
      </div>
    )
  }
}
